<template>
  <div>
      <h4>{{officeName}}</h4>
      <el-divider></el-divider>
      <el-image :src="officeImage" class="bgimg"  :preview-src-list="previewList"></el-image>
      <div style="margin-top:20px;">
          <strong>Click image to open expanded preview</strong>
      </div>
  </div>
</template>

<script>
export default {
    name: 'rentManagement',
    data() {
        return {
            oneNorth: 'https://ucommune.oss-ap-southeast-1.aliyuncs.com/onenorth.png',
            oue: 'https://ucommune.oss-ap-southeast-1.aliyuncs.com/oue.png',
            suntec: 'https://ucommune.oss-ap-southeast-1.aliyuncs.com/suntec.png',
            bugis: 'https://ucommune.oss-ap-southeast-1.aliyuncs.com/bugis.png'
        }
    },
    computed:{
        officeImage: function(){
            if (this.$route.params.officeName === 'onenorth'){
                return this.oneNorth
            } else if (this.$route.params.officeName === 'oue') {
                return this.oue
            } else if (this.$route.params.officeName === 'suntec') {
                return this.suntec
            } else if (this.$route.params.officeName === 'bugis') {
                return this.bugis
            }
            return ''
        },
        previewList: function(){
            if (this.$route.params.officeName === 'onenorth'){
                return [this.oneNorth]
            } else if (this.$route.params.officeName === 'oue') {
                return [this.oue]
            } else if (this.$route.params.officeName === 'suntec') {
                return [this.suntec]
            } else if (this.$route.params.officeName === 'bugis') {
                return [this.bugis]
            }
            return []
        },
        officeName: function(){
            if (this.$route.params.officeName === 'onenorth'){
                return 'One North Rent View'
            } else if (this.$route.params.officeName === 'oue') {
                return 'OUE Rent View'
            } else if (this.$route.params.officeName === 'suntec') {
                return 'Suntec Rent View'
            } else if (this.$route.params.officeName === 'bugis') {
                return 'Bugis Rent View'
            }
            return ''
        }
    }
}
</script>

<style>
.bgimg{
    width: 100%;
}
</style>